exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-axioms-md": () => import("./../../../src/pages/axioms.md" /* webpackChunkName: "component---src-pages-axioms-md" */),
  "component---src-pages-certifications-md": () => import("./../../../src/pages/certifications.md" /* webpackChunkName: "component---src-pages-certifications-md" */),
  "component---src-pages-definitions-md": () => import("./../../../src/pages/definitions.md" /* webpackChunkName: "component---src-pages-definitions-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-md": () => import("./../../../src/pages/me.md" /* webpackChunkName: "component---src-pages-me-md" */),
  "component---src-pages-projects-md": () => import("./../../../src/pages/projects.md" /* webpackChunkName: "component---src-pages-projects-md" */),
  "component---src-pages-quotes-md": () => import("./../../../src/pages/quotes.md" /* webpackChunkName: "component---src-pages-quotes-md" */),
  "component---src-pages-recommended-repos-md": () => import("./../../../src/pages/recommended-repos.md" /* webpackChunkName: "component---src-pages-recommended-repos-md" */),
  "component---src-pages-resources-md": () => import("./../../../src/pages/resources.md" /* webpackChunkName: "component---src-pages-resources-md" */),
  "component---src-pages-resume-md": () => import("./../../../src/pages/resume.md" /* webpackChunkName: "component---src-pages-resume-md" */),
  "component---src-pages-snippets-md": () => import("./../../../src/pages/snippets.md" /* webpackChunkName: "component---src-pages-snippets-md" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tech-spectrum-js": () => import("./../../../src/pages/tech-spectrum.js" /* webpackChunkName: "component---src-pages-tech-spectrum-js" */),
  "component---src-pages-thoughts-md": () => import("./../../../src/pages/thoughts.md" /* webpackChunkName: "component---src-pages-thoughts-md" */),
  "component---src-pages-tips-md": () => import("./../../../src/pages/tips.md" /* webpackChunkName: "component---src-pages-tips-md" */),
  "component---src-pages-training-md": () => import("./../../../src/pages/training.md" /* webpackChunkName: "component---src-pages-training-md" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

